import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalHistoricDetailsComponent } from 'src/app/templates/modal-historic-details/modal-historic-details.component';
import { Router } from '@angular/router';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { SeoService } from 'src/app/services/seo.service';

@Component({
  selector: 'app-historic',
  templateUrl: './historic.component.html',
  styleUrls: ['./historic.component.scss']
})
export class HistoricComponent implements OnInit {
  
  public faInfoCircle = faInfoCircle

  public isLoading: boolean = false

  public requests = []

  constructor(
    private api: ApiService,
    private auth: AuthService,
    private dialog: MatDialog,
    private router: Router,
    private seo: SeoService
  ) { }

  ngOnInit(): void {
    if(!this.auth.userVerify()) {
      this.router.navigate(['/'])
    } else {
      this.getRequests()
      this.setTags()
    }
  }

  private async getRequests() {
    this.isLoading = true
    await this.api.getWithHeaders(`client-api/requests/${this.auth.userVerify()._id}`, this.auth.userVerify().token).subscribe((data: any) => {
      this.isLoading = false
      if(data.status) {
        this.requests = data.data
      }
    })
  }

  public openDetails(items) {
    this.dialog.open(ModalHistoricDetailsComponent, {
      width: '900px',
      maxWidth: '',
      data: items
    })
  }

  private setTags() {
    this.seo.setTitle(`Histórico | Forentec`)
    this.seo.updateTag('og:title', `Histórico | Forentec`)
    this.seo.updateTag('og:description', 'Este é o seu histórico de cotações na Forentec')
    this.seo.updateTag('og:url', window.location.href)
    this.seo.updateTag('description', 'Este é o seu histórico de cotações na Forentec')
    this.seo.removeTag('keywords')
  }

}
