import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from 'src/app/services/cart.service';
import { faTrash, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { UtilsService } from 'src/app/services/utils.service';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import * as _ from 'lodash';
import { SeoService } from 'src/app/services/seo.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  public faTrash = faTrash
  public faInfoCircle = faInfoCircle

  public finalCart = []

  constructor(
    private router: Router,
    public cart: CartService,
    private api: ApiService,
    private auth: AuthService,
    private util: UtilsService,
    private seo: SeoService
  ) { }

  ngOnInit(): void {
    this.finalCart = this.cart.aggregate(this.cart.list())
    this.cart.listenCartEvents().subscribe(data => {
      if(data) {
        this.finalCart = this.cart.aggregate(this.cart.list())
      }
    })
    this.setTags()
  }

  public async send(items) {
    if(!this.auth.userVerify()) {
      this.util.swal('Espere!', 'Você precisa estar logado para enviar uma cotação', 'info', 'Fazer login').then(res => {
        if(res.value) {
          this.router.navigate(['/login'])
        }
      })
    } else {
      await this.api.getWithHeaders(`client-api/users/${this.auth.userVerify()._id}`, this.auth.userVerify().token).subscribe(async (data: any) => {
        const address = data.data.address
        if(address.cep && address.street && address.number && address.complement && address.neighborhood && address.city && address.state) {
          const objData = {
            items,
            client: {
              id: data.data._id,
              name: data.data.name,
              email: data.data.email,
              phone: data.data.phone,
              address: data.data.address
            }
          }
          
          await this.api.postWithHeaders(this.auth.userVerify().token, 'client-api/requests', objData).subscribe((data: any) => {
            if(data.status) {
              this.util.swal('Tudo certo!', data.msg, 'success').then(res => {
                if(res) {
                  this.cart.removeAll()
                  this.router.navigate(['/'])
                }
              })
            }
          }, (err: any) => {
            this.util.swal('Ops!', err.error.errors, 'error')
          })
        } else {
          this.util.swal('Espere!', 'Parece que seu endereço está incompleto, por favor complete seu endereço para solicitar um orçamento.', 'info', 'Atualizar endereço').then(res => {
            if(res.value) {
              this.router.navigate(['/perfil'])
            }
          })
        }
      })
    }
  }

  private setTags() {
    this.seo.setTitle(`Carrinho | Forentec`)
    this.seo.updateTag('og:title', `Carrinho | Forentec`)
    this.seo.updateTag('og:description', 'Finalize sua cotação.')
    this.seo.updateTag('og:url', window.location.href)
    this.seo.updateTag('description', 'Finalize sua cotação.')
    this.seo.removeTag('keywords')
  }

}
