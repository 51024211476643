import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    private meta: Meta,
    private title: Title
  ) { }

  public setTitle(text: string) {
    this.title.setTitle(text)
  }

  public addTag(property: string, content: string) {
    if(property.includes('og:')) {
      this.meta.addTag({property, content})
    } else {
      this.meta.addTag({name: property, content})
    }
  }

  public updateTag(property: string, content: string) {
    if(property.includes('og:')) {
      this.meta.updateTag({property, content})
    } else { 
      this.meta.updateTag({name: property, content})
    }
  }

  public removeTag(property: string) {
    this.meta.removeTag(property)
  }
}
