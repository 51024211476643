<section id="checkout" class="page-begin">
    <app-search-bar></app-search-bar>
    
    <div class="container content">
        <div class="row">
            <div class="col-12 col-lg-10 offset-lg-1">
                <h2 class="title-line mb-5">
                    Carrinho
                    <span class="line end"></span>
                </h2>

                <div class="alert alert-info" *ngIf="!cart.aggregate(cart.cart).length">
                    <fa-icon [icon]="faInfoCircle"></fa-icon>
                    Seu carrinho está vazio!
                </div>

                <div *ngIf="cart.aggregate(cart.cart).length">
                    <div class="table-responsive">
                        <div class="d-flex justify-content-end mb-2">
                            <button class="btn btn-sm btn-danger" (click)="cart.removeAll()"><fa-icon [icon]="faTrash"></fa-icon> Limpar carrinho</button>
                        </div>
                        <table class="table table-hovered table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Produto</th>
                                    <th>Cód.</th>
                                    <th>Qtd.</th>
                                    <th>Remover</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of finalCart | uniq:'_id'; let i = index">
                                    <td>{{i+1}}</td>
                                    <td>{{item.title}}</td>
                                    <td>{{item.cod}}</td>
                                    <td style="width: 110px;">
                                        <input type="number" min="1" class="form-control" [(ngModel)]="item.count" style="min-width: 70px;">
                                    </td>
                                    <td style="width: 50px;">
                                        <button class="btn btn-sm btn-danger btn-block" (click)="cart.removeItem(item)">
                                            <fa-icon [icon]="faTrash"></fa-icon>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <hr>
                    <div class="d-flex justify-content-end mb-2">
                        <button class="btn main-btn btn-block-xs" matRipple (click)="send(finalCart)">Enviar cotação</button>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</section>