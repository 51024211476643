import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { SeoService } from 'src/app/services/seo.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public products = []
  public bannerFooter = []

  constructor(
    private api: ApiService,
    public util: UtilsService,
    private seo: SeoService
  ) { }

  ngOnInit(): void {
    this.getProducts()
    this.getBannerFooter()
    this.setTags()
  }

  public async getProducts() {
    await this.api.get('oapi/products').subscribe((data: any) => {
      if(data.status) {
        this.products = data.data.filter(val => val.active === true && val.detach === true)
      }
    })
  }

  public async getBannerFooter() {
    await this.api.get('oapi/contents').subscribe((data: any) => {
      if(data.status) {
        this.bannerFooter = data.data.filter(val => val.section === 'Banner Rodapé' && val.active === true)
      }
    })
  }

  public carouselProductsOptions: OwlOptions = {
    loop: true,
    autoplay: false,
    autoplayHoverPause: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 250,
    dotsSpeed: 250,
    autoplaySpeed: 700,
    navText: ['<i class="fas fa-chevron-circle-left"></i>', '<i class="fas fa-chevron-circle-right"></i>'],
    margin: 10,
    items: 4,
    nav: true,
    slideBy: 4,
    responsive: {
      0: {
        items: 2,
        nav: false
      },
      400: {
        items: 3,
        nav: false
      },
      740: {
        items: 4
      }
    }
  }

  public carouselBannerFooterOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    autoplayHoverPause: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    dotsSpeed: 700,
    autoplaySpeed: 700,
    navText: ['<i class="fas fa-chevron-circle-left"></i>', '<i class="fas fa-chevron-circle-right"></i>'],
    margin: 10,
    items: 1,
    nav: true,
    responsive: {
      0: {
        nav: false
      },
      400: {
        nav: false
      },
      740: {
        nav: true
      }
    }
  }

  private setTags() {
    this.seo.setTitle(`Home | Forentec`)
    this.seo.updateTag('og:title', `Home | Forentec`)
    this.seo.updateTag('og:description', 'Bem-vindo à Forentec! Confiança, qualidade e segurança para suas análises.')
    this.seo.updateTag('og:url', window.location.href)
    this.seo.updateTag('description', 'Bem-vindo à Forentec! Confiança, qualidade e segurança para suas análises.')
    this.seo.updateTag('keywords', 'sangue, saliva, sêmen, amilase, mancha, imunocromatográfico, pesquisa, forense, fonrensics, seratec, hemoglobina, hem, menstrual, psa, pmb, fob, oculto')
  }

}
