<div matDialogTitle>
    <h2>Detalhes</h2>
</div>
<mat-dialog-content>
    <div class="table-responsive">
        <table class="table table-hovered table-striped table-bordered">
            <thead>
                <tr>
                    <th>Produto</th>
                    <th>Cód.</th>
                    <th>Qtd.</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of data">
                    <td>
                        <img [src]="item.img" style="width: 60px; max-width: 100%; margin-right: 10px;">
                        {{item.title}}
                    </td>
                    <td>{{item.cod}}</td>
                    <td>{{item.count}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-end">
    <button mat-button (click)="close()">Fechar</button>
</mat-dialog-actions>