import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient
  ) { }

  public get = (endpoint) => this.http.get(`${environment.baseUrl}/${endpoint}`)

  public post = (endpoint, data) => this.http.post(`${environment.baseUrl}/${endpoint}`, data)
  
  public put = (endpoint, data) => this.http.put(`${environment.baseUrl}/${endpoint}`, data)

  public getWithHeaders(endpoint, token) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': token
      })
    }
    return this.http.get(`${environment.baseUrl}/${endpoint}`, httpOptions)
  }

  public postWithHeaders(token, endpoint, data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': token
      })
    }
    return this.http.post(`${environment.baseUrl}/${endpoint}`, data, httpOptions)
  }
  
  public putWithHeaders(token, endpoint, data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': token
      })
    }
    return this.http.put(`${environment.baseUrl}/${endpoint}`, data, httpOptions)
  }
}
