<div class="search-bar content">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-8 offset-md-2">
                <div class="blocos">
                    <ng-container *ngFor="let category of categories">
                        <a class="bloco" [routerLink]="['/produtos']" [queryParams]="{categoria:category._id}" matRipple>
                            <fa-icon [icon]="category.title !== 'Reagentes' ? (category.title === 'Equipamentos' ? faMicroscope : faVials) : faFlask"></fa-icon>
                            <p>{{category.title}}</p>
                        </a>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="row mt-5 mt-xs-30">
            <div class="col-12">
                <div class="search-zone">
                    <p>Procurando algo em específico?</p>
                    <div class="input-group">
                        <input type="text" class="form-control" placeholder="Encontre o produto que você procura" [(ngModel)]="q" (keydown.enter)="search(q)">
                        <button class="btn" type="button" (click)="search(q)">
                            <fa-icon [icon]="faSearch"></fa-icon>
                        </button>
                    </div>
                    <button mat-button class="carrinho" [matMenuTriggerFor]="menu">Produtos selecionados ({{cart.cart.length}}) <fa-icon [icon]="faCaretDown"></fa-icon></button>
                    <mat-menu #menu="matMenu">
                        <div *ngIf="cart.aggregate(cart.cart).length">
                            <button mat-menu-item (click)="cart.removeAll()" style="color: #dc3545;">
                                <mat-icon style="color: #dc3545; margin-right: 3px;">delete</mat-icon>
                                Limpar carrinho
                            </button>
                            <mat-divider></mat-divider>
                            <ng-container *ngFor="let item of cart.aggregate(cart.cart) | uniq:'_id'">
                                <button mat-menu-item><b>({{item.count}}x)</b> {{item.title}}</button>
                            </ng-container>
                            <mat-divider></mat-divider>
                            <button mat-menu-item routerLink="/carrinho">
                                <mat-icon style="margin-right: 6px;">shopping_basket</mat-icon>
                                Finalizar pedido
                            </button>
                        </div>
                        <div class="alert alert-info" *ngIf="!cart.aggregate(cart.cart).length" style="margin: 10px 15px;">
                            <fa-icon [icon]="faInfoCircle"></fa-icon>
                            Seu carrinho está vazio!
                        </div>
                    </mat-menu>
                </div>
            </div>
        </div>
    </div>
</div>