import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidationErrors } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { NgxViacepService } from '@brunoc/ngx-viacep';
import { MatDialog } from '@angular/material/dialog';
import { ModalResetPasswordComponent } from 'src/app/templates/modal-reset-password/modal-reset-password.component';
import { SeoService } from 'src/app/services/seo.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  formLogin: FormGroup
  formSignup: FormGroup
  
  public states = []

  public isLoadingSignup: boolean = false
  public isLoadingLogin: boolean = false

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private api: ApiService,
    private util: UtilsService,
    private auth: AuthService,
    private router: Router,
    private viacep: NgxViacepService,
    private dialog: MatDialog,
    private seo: SeoService
  ) { }

  ngOnInit(): void {
    if(this.auth.userVerify()) this.router.navigate(['/'])
    this.prepareFormLogin()
    this.prepareFormSignup()
    this.setTags()
  }

  private prepareFormLogin() {
    this.formLogin = this.fb.group({
      email: this.fb.control('', [Validators.email]),
      password: this.fb.control('', [Validators.required])
    })
  }

  private prepareFormSignup() {
    this.formSignup = this.fb.group({
      name: this.fb.control('', [Validators.required]),
      email: this.fb.control('', [Validators.email, Validators.required]),
      phone: this.fb.control(''),
      address: this.fb.group({
        cep: this.fb.control(''),
        street: this.fb.control(''),
        number: this.fb.control(null),
        complement: this.fb.control(''),
        neighborhood: this.fb.control(''),
        city: this.fb.control(''),
        state: this.fb.control('')
      }),
      password: this.fb.control('', [Validators.required]),
      confirmPassword: this.fb.control('', [Validators.required])
    })
    this.getStates()
  }

  private async getStates() {
    await this.http.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados').subscribe((data: any) => {
      this.states = data
    })
  }

  public async getAddressByCep(cep) {
    await this.viacep.buscarPorCep(cep).then((data: any) => {
      if(data) {
        const address = {
          street: data.logradouro,
          neighborhood: data.bairro,
          city: data.localidade,
          state: data.uf
        }
        this.formSignup.patchValue({ address })
      }
     }).catch((err: any) => {
      console.log(err.message)
     })
  }

  public async signup() {
    if(!this.formSignup.valid) {
      const arr = this.util.getFormValidationErrors(this.formSignup)
      const newArr = arr.map(el => this.util.translate(el.field)).join(', ')
      this.util.swal('Ops!', `Preencha todos os campos obrigatórios.<br/> <b>${newArr}</b>`, 'error')
    } else {
      this.isLoadingSignup = true
      await this.api.post('oapi/signup', this.formSignup.value).subscribe((data: any) => {
        this.isLoadingSignup = false
        this.util.swal('Tudo certo!', data.msg, 'success')
        this.formSignup.reset()
      }, (err: any) => {
        this.isLoadingSignup = false
        this.util.swal('Ops!', err.error.errors, 'error')
      })
    }
  }

  public async login() {
    this.isLoadingLogin = true
    await this.api.post('oapi/login', this.formLogin.value).subscribe((data: any) => {
      this.isLoadingLogin = false
      if(data.token) {
        this.auth.setUser(data)
        this.util.toast('Seja bem-vindo!', 'success')
        this.router.navigate(['/'])
      } else {
        this.util.swal('Ops!', data.errors[0], 'error')
      }
    }, (err: any) => {
      this.isLoadingLogin = false
      this.util.swal('Ops!', err.error.errors, 'error')
    })
  }

  public resetPassword(ev) {
    ev.preventDefault()
    this.dialog.open(ModalResetPasswordComponent, {
      width: '500px',
      maxWidth: ''
    })
  }

  private setTags() {
    this.seo.setTitle(`Login/Cadastro | Forentec`)
    this.seo.updateTag('og:title', `Login/Cadastro | Forentec`)
    this.seo.updateTag('og:description', 'Faça agora mesmo seu login no nosso site e peça uma cotação!')
    this.seo.updateTag('og:url', window.location.href)
    this.seo.updateTag('description', 'Faça agora mesmo seu login no nosso site e peça uma cotação!')
    this.seo.updateTag('keywords', 'sangue, saliva, sêmen, amilase, mancha, imunocromatográfico, pesquisa, forense, fonrensics, seratec, hemoglobina, hem, menstrual, psa, pmb, fob, oculto')
  }

}
