<section id="login" class="page-begin">
    <app-search-bar></app-search-bar>
    
    <div class="container content">
        <div class="row">
            <div class="col-12 col-lg-4 mb-xs-30 mb-sm-30">
                <div class="login">
                    <h3 class="login-title">Já sou cadastrado</h3>

                    <form [formGroup]="formLogin">
                        <div class="row">
                            <div class="col-12 mb-3">
                                <label for="emailLogin">E-mail</label>
                                <input type="email" class="form-control" id="emailLogin" formControlName="email" required>
                            </div>
                            <div class="col-12 mb-3">
                                <label for="passwordLogin">Senha</label>
                                <input type="password" class="form-control" id="passwordLogin" formControlName="password" required>
                            </div>
                            <div class="col-12 mb-3">
                                <div class="row">
                                    <div class="col-12 col-lg-6 order-lg-2 mb-xs-15 mb-sm-15">
                                        <button type="submit" class="btn main-btn btn-block" matRipple (click)="login()" [disabled]="isLoadingLogin">Entrar</button>
                                    </div>
                                    <div class="col-12 col-lg-6 order-lg-1 d-flex align-items-end">
                                        <a href="" (click)="resetPassword($event)">Esqueci minha senha</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-12 col-lg-7 offset-lg-1">
                <div class="signup">
                    <h3 class="signup-title">Não sou cadastrado</h3>

                    <form [formGroup]="formSignup">
                        <div class="row">
                            <div class="col-12 col-lg-6 mb-3">
                                <label for="nameSignup">Nome *</label>
                                <input type="text" class="form-control" id="nameSignup" formControlName="name" required>
                            </div>
                            <div class="col-12 col-lg-6 mb-3">
                                <label for="emailSignup">E-mail *</label>
                                <input type="email" class="form-control" id="emailSignup" formControlName="email" required>
                            </div>
                            <div class="col-12 col-lg-6 mb-3">
                                <label for="phoneSignup">Telefone</label>
                                <input type="text" class="form-control" id="phoneSignup" formControlName="phone" mask="(00) 0000-0000 || (00) 0 0000-0000" [dropSpecialCharacters]="false">
                            </div>
                            <ng-container formGroupName="address">
                                <div class="col-12 col-lg-6 mb-3">
                                    <label for="cepSignup">CEP</label>
                                    <input type="text" class="form-control" id="cepSignup" formControlName="cep" mask="00000-000" [dropSpecialCharacters]="false" (change)="getAddressByCep(formSignup.value.address.cep)">
                                </div>
                                <div class="col-12 col-lg-9 mb-3">
                                    <label for="streetSignup">Rua</label>
                                    <input type="text" class="form-control" id="streetSignup" formControlName="street">
                                </div>
                                <div class="col-12 col-lg-3 mb-3">
                                    <label for="numberSignup">Número</label>
                                    <input type="number" class="form-control" id="numberSignup" min="1" formControlName="number">
                                </div>
                                <div class="col-12 col-lg-4 mb-3">
                                    <label for="complementSignup">Complemento</label>
                                    <input type="text" class="form-control" id="complementSignup" formControlName="complement">
                                </div>
                                <div class="col-12 col-lg-8 mb-3">
                                    <label for="neighborhoodSignup">Bairro</label>
                                    <input type="text" class="form-control" id="neighborhoodSignup" formControlName="neighborhood">
                                </div>
                                <div class="col-12 col-lg-8 mb-3">
                                    <label for="citySignup">Cidade</label>
                                    <input type="text" class="form-control" id="citySignup" formControlName="city">
                                </div>
                                <div class="col-12 col-lg-4 mb-3">
                                    <label for="stateSignup">Estado</label>
                                    <select class="form-control" id="stateSignup" formControlName="state">
                                        <option value="" disabled selected>Selecione um estado</option>
                                        <option *ngFor="let state of states | orderBy:'sigla':'asc'" [value]="state.sigla">{{state.sigla}}</option>
                                    </select>
                                </div>
                            </ng-container>
                            <div class="col-12 col-lg-6 mb-3">
                                <label for="passwordSignup">Senha *</label>
                                <input type="password" class="form-control" id="passwordSignup" formControlName="password" required>
                            </div>
                            <div class="col-12 col-lg-6 mb-3">
                                <label for="confirmPasswordSignup">Confirmar senha *</label>
                                <input type="password" class="form-control" id="confirmPasswordSignup" formControlName="confirmPassword" required>
                            </div>
                            <div class="col-12 d-flex justify-content-end">
                                <button type="submit" class="btn main-btn btn-block-xs btn-block-sm" matRipple (click)="signup()" [disabled]="isLoadingSignup">Cadastrar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>