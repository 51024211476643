import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-modal-change-password',
  templateUrl: './modal-change-password.component.html',
  styleUrls: ['./modal-change-password.component.scss']
})
export class ModalChangePasswordComponent implements OnInit {
  formPassword: FormGroup

  public isLoading: boolean = false

  public icon1 = faEye
  public icon2 = faEye
  public icon3 = faEye

  public input1 = 'password'
  public input2 = 'password'
  public input3 = 'password'

  constructor(
    private dialogRef: MatDialogRef<ModalChangePasswordComponent>,
    private fb: FormBuilder,
    private api: ApiService,
    private auth: AuthService,
    private util: UtilsService
  ) { }

  ngOnInit(): void {
    this.prepareForm()
  }

  private prepareForm() {
    this.formPassword = this.fb.group({
      actualPassword: this.fb.control('', [Validators.required]),
      password: this.fb.control('', [Validators.required]),
      confirmPassword: this.fb.control('', [Validators.required]),
      userId: this.fb.control(this.auth.userVerify()._id)
    })
  }

  close(): void {
    this.dialogRef.close()
  }

  public reveal(id) {
    switch(id) {
      case '#actualPassword':
        this.icon1 === faEye ? this.icon1 = faEyeSlash : this.icon1 = faEye
        this.input1 === 'password' ? this.input1 = 'text' : this.input1 = 'password'
        break;
      case '#password':
        this.icon2 === faEye ? this.icon2 = faEyeSlash : this.icon2 = faEye
        this.input2 === 'password' ? this.input2 = 'text' : this.input2 = 'password'
        break;
      case '#confirmPassword':
        this.icon3 === faEye ? this.icon3 = faEyeSlash : this.icon3 = faEye
        this.input3 === 'password' ? this.input3 = 'text' : this.input3 = 'password'
        break;
    }
  }

  public send() {
    if(!this.formPassword.valid) {
      const arr = this.util.getFormValidationErrors(this.formPassword)
      const newArr = arr.map(el => this.util.translate(el.field)).join(', ')
      this.util.swal('Ops!', `Preencha todos os campos obrigatórios.<br/> <b>${newArr}</b>`, 'error')
    } else {
      this.isLoading = true
      this.api.putWithHeaders(this.auth.userVerify().token, `client-api/change-password/${this.auth.userVerify()._id}`, this.formPassword.value).subscribe((data: any) => {
        this.isLoading = false
        if(data.status) {
          this.close()
          this.util.swal('Tudo certo!', data.msg, 'success')
        }
      }, (err: any) => {
        this.isLoading = false
        this.util.swal('Ops!', err.error.errors, 'error')
      })
    }
  }

}
