import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from 'src/app/services/utils.service';
import { faClipboardList, faFile } from '@fortawesome/free-solid-svg-icons';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CartService } from 'src/app/services/cart.service';
import { SeoService } from 'src/app/services/seo.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  public faClipboardList = faClipboardList
  public faFile = faFile
  
  public categories = []
  public category: any = {}

  public products = []
  public product: any = {}
  public idProduct

  public isLoading: boolean = false
  public isLoadingCategories: boolean = false
  
  public step: number = null

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    public util: UtilsService,
    private router: Router,
    public cart: CartService,
    private seo: SeoService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((data: any) => {
      this.idProduct = data.id
      this.getProduct()
    })
  }

  private getCategories() {
    this.isLoadingCategories = true
    this.api.get('oapi/categories').subscribe((data: any) => {
      if(data.status) {
        this.categories = data.data
        this.category = data.data.filter(val => val._id === this.product.idCategory)[0]
        this.getSubCategories(this.category._id)
        this.isLoadingCategories = false
      }
    })
  }

  private getSubCategories(idCategory) {
    this.api.get(`oapi/subcategories/${idCategory}`).subscribe((data: any) => {
      if(data.status) {
        this.category.subcategories = data.data.filter(val => val.active === true)
      }
    })
  }

  private getProduct() {
    this.isLoading = true
    this.api.get('oapi/products').subscribe((data: any) => {
      this.isLoading = false
      if(data.status) {
        const products = data.data.filter(val => val._id !== this.idProduct && val.active === true)
        this.product = data.data.filter(val => val._id === this.idProduct)[0]

        if(!this.product.active) {
          this.router.navigate(['/'])
        }

        this.setTags()
        this.getCategories()

        // Condição para mostrar os produtos relacionados
        const subCategoriesLength = products.map(el => el.idSubCategory).filter(val => val === this.product.idSubCategory).length // Faz um map do array de produtos pelo id da subcategoria, filtra o map pelo mesmo valor da subcategoria do produto exibido e pega o valor total de itens nesse array criado pelo map
        if(subCategoriesLength > 3) { // Verifica se o total de valores do array é maior do que 3
          this.products = products.filter(val => val.idSubCategory === this.product.idSubCategory) // Então ele popula this.products com os produtos filtrados pelo id de subcategoria sendo igual ao id de subcategoria do produto
        } else { // Caso não aconteça nenhuma das duas condições acima
          this.products = products.filter(val => val.idCategory === this.product.idCategory) // Então ele popula this.products com os produtos filtrados pelo id de categoria sendo igual ao id de categoria do produto
        }
      }
    })
  }

  public filter(e, categ, subcateg) {
    e.preventDefault()
    this.router.navigate(['/produtos'], { queryParams: { categoria: categ, subcategoria: subcateg }, queryParamsHandling: 'merge' })
  }

  public setStep(index: number) {
    this.step = index
  }

  public customOptions: OwlOptions = {
    loop: true,
    autoplay: false,
    autoplayHoverPause: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 250,
    dotsSpeed: 250,
    autoplaySpeed: 700,
    navText: ['<i class="fas fa-chevron-circle-left"></i>', '<i class="fas fa-chevron-circle-right"></i>'],
    margin: 10,
    items: 4,
    nav: true,
    slideBy: 4,
    responsive: {
      0: {
        items: 2,
        nav: false
      },
      400: {
        items: 3,
        nav: false
      },
      740: {
        items: 4
      }
    }
  }

  private setTags() {
    this.seo.setTitle(`${this.product.title} | Forentec`)
    this.seo.updateTag('og:title', `${this.product.title} | Forentec`)
    this.seo.updateTag('og:description', this.product.description.length >= 150 ? this.product.description.slice(0,147)+'...' : this.product.description)
    this.seo.updateTag('og:url', window.location.href)
    this.seo.updateTag('description', this.product.description.length >= 150 ? this.product.description.slice(0,147)+'...' : this.product.description)
    this.seo.updateTag('keywords', this.product.tags.join(', '))
  }

}
