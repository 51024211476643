<section id="contact" class="page-begin">
    <app-search-bar></app-search-bar>
    
    <div class="container content">
        <div class="row">
            <!-- <div class="col-12 col-md-8 offset-md-2 mb-5">
                <div class="contact-tabs">
                    <div class="contact-tab" [class.active]="tabActive === 1" (click)="tabActive = 1">Fale conosco</div>
                    <div class="contact-tab" [class.active]="tabActive === 2" (click)="tabActive = 2">Trabalhe conosco</div>
                </div>
            </div> -->

            <div class="col-12 col-md-10 offset-md-1">
                <h3 class="contact-title">Fale conosco</h3>

                <form [formGroup]="formContact" *ngIf="tabActive === 1">
                    <div class="row">
                        <div class="col-12 col-md-4 mb-4">
                            <label for="sector">Setor com o qual deseja contato *</label>
                            <select class="form-control" id="sector" formControlName="sector" required>
                                <option value="" disabled selected>Selecione um setor</option>
                                <option *ngFor="let sector of sectors | orderBy:[]:'asc'" [value]="sector">{{sector}}</option>
                            </select>
                        </div>
                        <div class="col-12 col-md-4 mb-4">
                            <label for="subject">Assunto *</label>
                            <input type="text" class="form-control" id="subject" formControlName="subject" required>
                        </div>
                        <div class="col-12 col-md-4 mb-4">
                            <label for="name">Seu nome *</label>
                            <input type="text" class="form-control" id="name" formControlName="name" required>
                        </div>
                        <div class="col-12 col-md-4 mb-4">
                            <label for="company">Empresa/Instituição</label>
                            <input type="text" class="form-control" id="company" formControlName="company">
                        </div>
                        <div class="col-12 col-md-4 mb-4">
                            <label for="email">E-mail *</label>
                            <input type="email" class="form-control" id="email" formControlName="email" required>
                        </div>
                        <div class="col-12 col-md-4 mb-4">
                            <label for="phone">Telefone</label>
                            <input type="text" class="form-control" id="phone" formControlName="phone" mask="(00) 0000-0000 || (00) 0 0000-0000" [dropSpecialCharacters]="false">
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="mb-4">
                                <label for="state">Estado</label>
                                <select class="form-control" id="state" formControlName="state">
                                    <option value="" disabled selected>Selecione um estado</option>
                                    <option *ngFor="let state of states | orderBy:'sigla':'asc'" [value]="state.sigla">{{state.sigla}}</option>
                                </select>
                            </div>
                            <div class="mb-4">
                                <label for="city">Cidade</label>
                                <input type="text" class="form-control" id="city" formControlName="city">
                            </div>
                        </div>
                        <div class="col-12 col-md-8">
                            <label for="message">Mensagem *</label>
                            <textarea class="form-control" id="message" formControlName="message" required></textarea>
                        </div>
                        <div class="col-12 mt-xs-30 d-flex justify-content-end">
                            <button type="submit" class="btn main-btn btn-block-xs" matRipple (click)="send()" [disabled]="isLoading">Enviar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>