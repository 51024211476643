import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-modal-reset-password',
  templateUrl: './modal-reset-password.component.html',
  styleUrls: ['./modal-reset-password.component.scss']
})
export class ModalResetPasswordComponent implements OnInit {

  public email: string = ''
  public isLoading: boolean = false

  constructor(
    private dialogRef: MatDialogRef<ModalResetPasswordComponent>,
    private api: ApiService,
    private util: UtilsService
  ) { }

  ngOnInit(): void {
  }

  public async send() {
    this.isLoading = true
    await this.api.post('oapi/check-user-email', {email: this.email}).subscribe((data: any) => {
      this.isLoading = false
      if(data.status) {
        this.util.swal('Tudo certo!', data.msg, 'success')
        this.email = ''
        this.close()
      }
    }, (err: any) => {
      this.isLoading = false
      this.util.swal('Ops!', err.error.errors, 'error')
    })
  }

  close(): void {
    this.dialogRef.close()
  }

}
