import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { ApiService } from 'src/app/services/api.service';
import { faPhone, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Contacts } from 'src/app/models/contacts.model';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public faPhone = faPhone
  public faWhatsapp = faWhatsapp
  public faCaretDown = faCaretDown

  public user: any = {}
  public name: string = ''

  public contacts: Contacts[] = []
  public isLoading: boolean = false
  public categories = []

  public sidebarCollapsed = true
  
  public mobileScreen = $(window).width() < 992

  constructor(
    private api: ApiService,
    private auth: AuthService,
    private router: Router,
    public util: UtilsService
  ) { }

  ngOnInit(): void {
    this.getContacts()
    this.getCategories()
    this.auth.getLoginEvent().subscribe(data => {
      if(data.token) {  
        this.user = data
        this.name = data.name.split(' ')[0]
      } else {
        this.user = data
        this.name = data.name
      }
    })
    if(this.auth.userVerify()) {
      this.user = this.auth.userVerify()
      this.name = this.user.name.split(' ')[0]
    }
  }

  public sidebarCollapse() {
    this.sidebarCollapsed = !this.sidebarCollapsed
    this.closeMenuCollapse()
  }

  private async getContacts() {
    await this.api.get('oapi/contacts').subscribe((data: any) => {
      if(data.status) {
        this.contacts = data.data.filter(val => val.active === true)
      }
    })
  }

  private async getCategories() {
    this.isLoading = true
    await this.api.get('oapi/categories').subscribe((data: any) => {
      if(data.status) {
        this.isLoading = false
        this.categories = data.data.filter(val => val.active === true)
      }
    })
  }

  public logout() {
    this.auth.logout()
    if(window.location.pathname.includes('/perfil') || window.location.pathname.includes('/historico')) this.router.navigate(['/'])
  }

  public menuCollapse(id) {
    document.getElementById(id).classList.toggle('collapsed')
  }
  
  private closeMenuCollapse() {
    document.querySelectorAll('.menu-collapse').forEach(el => {
      if(!el.classList.contains('collapsed')) {
        el.classList.add('collapsed')
      }
    })
  }

}