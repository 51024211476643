<div matDialogTitle>
    <h2>Esqueceu a senha?</h2>
</div>
<mat-dialog-content (keydown.enter)="send()">
    <p>Informe seu email, lhe enviaremos as instruções necessárias para recuperar sua senha</p>
    <label>Seu e-mail</label>
    <input type="email" class="form-control mb-3" [(ngModel)]="email" required>
</mat-dialog-content>
<mat-dialog-actions class="form-confirm">
    <button class="btn main-btn" (click)="close()" matRipple>Fechar</button>
    <button class="btn main-btn" (click)="send()" matRipple [disabled]="isLoading">Enviar</button>
</mat-dialog-actions>