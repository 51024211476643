import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  public banners = []

  constructor(
    private api: ApiService,
    public util: UtilsService
  ) { }

  ngOnInit(): void {
    this.getBanners()
  }

  public async getBanners() {
    await this.api.get('oapi/contents').subscribe((data: any) => {
      if(data.status) {
        this.banners = data.data.filter(val => val.section === 'Banner Topo' && val.active === true)
      }
    })
  }

  public customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    autoplayHoverPause: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    autoplaySpeed: 700,
    navText: ['<i class="fas fa-chevron-circle-left"></i>', '<i class="fas fa-chevron-circle-right"></i>'],
    margin: 10,
    items: 1,
    nav: true,
    responsive: {
      0: {
        nav: false,
        dots: true
      },
      400: {
        nav: false,
        dots: true
      },
      740: {
        nav: true
      }
    }
  }

}
