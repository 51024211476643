<section id="products" class="page-begin">
    <app-search-bar [q]="queryParams.q"></app-search-bar>
    
    <div class="container content">
        <div class="row">
            <div class="col-12 col-md-3 mb-xs-15" *ngIf="!searchMode">
                <div class="loader-container" *ngIf="isLoadingCategories">
                    <mat-spinner strokeWidth="6"></mat-spinner>
                </div>
                <div class="categories" *ngIf="!isLoadingCategories">
                    <div class="categories-title">
                        <p>{{category.title}}</p>
                    </div>
                    <mat-card>
                        <mat-card-content>
                            <ul class="list-unstyled">
                                <li [class.active]="queryParams.categoria === category._id && !queryParams.subcategoria">
                                    <a href="" (click)="filter($event, category._id, null)">Todos</a>
                                </li>
                                <li *ngFor="let subcategory of category.subcategories; let i = index"
                                    [class.active]="queryParams.subcategoria === subcategory._id">
                                    <a href="" (click)="filter($event, category._id, subcategory._id)">{{subcategory.title}}</a>
                                </li>
                            </ul>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>

            <div class="col-12" [ngClass]="{'col-md-9 col-lg-8 offset-lg-1': !searchMode}">
                <div class="loader-container" *ngIf="isLoading">
                    <mat-spinner strokeWidth="6"></mat-spinner>
                </div>
                <div class="products" *ngIf="!isLoading">
                    <div class="products-title mb-3">
                        <p>{{searchMode ? (products.length !== 1 ? 'Total de ' + products.length + ' produtos encontrados' : 'Total de ' + products.length + ' produto encontrado') : 'Produtos em destaque'}}</p>
                    </div>

                    <div class="alert alert-info" *ngIf="!isLoading && !products.length">
                        <fa-icon [icon]="faInfoCircle"></fa-icon>
                        Nenhum produto encontrado
                    </div>

                    <div class="row">
                        <div class="col-12 col-md-4 mb-3" *ngFor="let product of products | orderBy:{'detach': true}:'desc'">
                            <div class="box-product">
                                <a [routerLink]="['/produto', product._id]">
                                    <div class="box-img d-flex align-center justify-content-center">
                                        <img [src]="product.img">
                                    </div>
                                    <hr>
                                    <h3 class="product-title">{{product.title}}</h3>
                                    <p>Ver informações</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>