<div class="sidebar-backdrop" [class.backdrop-active]="!sidebarCollapsed" (click)="sidebarCollapse()"></div>
<nav class="navbar navbar-expand-lg fixed-top">
    <div class="container">
        <a class="navbar-brand" routerLink="/">
            <img src="assets/img/logo.png">
        </a>
        <button class="navbar-toggler hamburger hamburger--collapse" type="button"
                [ngClass]="{'is-active': !sidebarCollapsed}"
                (click)="sidebarCollapse()"
                aria-expanded="false" aria-label="Toggle navigation">
            <span class="hamburger-box">
                <span class="hamburger-inner"></span>
            </span>
        </button>
    
        <div class="navbar-right navbar-mobile" [class.opened]="!sidebarCollapsed">
            <ul class="navbar-nav mr-auto mb-3 nav-contacts">
                <li class="nav-item" *ngFor="let contact of contacts" [hidden]="contact.service === 'E-mail'">
                    <a class="nav-link" href="{{contact.service === 'WhatsApp' ? 'https://api.whatsapp.com/send?phone=55'+util.formatNumber(contact.contact) : 'tel:+55'+contact.contact}}" target="_blank">
                        <fa-icon [class.whatsapp-icon]="contact.service === 'WhatsApp'" [icon]="contact.service === 'WhatsApp' ? faWhatsapp : faPhone"></fa-icon>
                        {{contact.service === 'Telefone' ? contact.contact : 'Atendimento pelo WhatsApp'}}
                    </a>
                </li>
            </ul>
            <ul class="navbar-nav mr-auto">
                <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" (click)="sidebarCollapse()" routerLink="/">Início</a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link" (click)="sidebarCollapse()" routerLink="/sobre">A Forentec</a>
                </li>
                <li class="nav-item" routerLinkActive="active" *ngFor="let category of categories">
                    <a class="nav-link" (click)="sidebarCollapse()" [routerLink]="['/produtos']" [queryParams]="{categoria:category._id}">{{category.title}}</a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link" (click)="sidebarCollapse()" routerLink="/contato">Contato</a>
                </li>
                <li class="nav-item" routerLinkActive="active" *ngIf="!user.token">
                    <a class="nav-link" (click)="sidebarCollapse()" routerLink="/login">Cadastre-se | Login</a>
                </li>
                <li class="nav-item d-none d-lg-block" routerLinkActive="active" *ngIf="user.token">
                    <a class="nav-link" [matMenuTriggerFor]="menu">Olá, {{name}} <fa-icon [icon]="faCaretDown"></fa-icon></a>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item routerLink="/perfil">
                            <mat-icon>account_circle</mat-icon>
                            <span>Meu perfil</span>
                        </button>
                        <button mat-menu-item routerLink="/historico">
                            <mat-icon>history</mat-icon>
                            <span>Histórico</span>
                        </button>
                        <mat-divider></mat-divider>
                        <button mat-menu-item (click)="logout()">
                            <mat-icon>power_settings_new</mat-icon>
                            <span>Sair</span>
                        </button>
                    </mat-menu>
                </li>
                <li class="nav-item d-lg-none" *ngIf="user.token">
                    <a class="nav-link" (click)="menuCollapse('collapse1')" matRipple>Olá, {{name}} <fa-icon [icon]="faCaretDown"></fa-icon></a>
                    <ul id="collapse1" class="menu-collapse collapsed">
                        <li><a routerLink="/perfil" routerLinkActive="active" (click)="sidebarCollapse()">Meu perfil</a></li>
                        <li><a routerLink="/historico" routerLinkActive="active" (click)="sidebarCollapse()">Histórico</a></li>
                        <li><a (click)="logout(); sidebarCollapse()">Sair</a></li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</nav>