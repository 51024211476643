import { Component, OnInit, Input } from '@angular/core';
import { faFlask, faMicroscope, faVials, faSearch, faCaretDown, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { CartService } from 'src/app/services/cart.service';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {

  @Input() public q: string = ''

  public isLoading: boolean = false
  public categories = []

  public faFlask = faFlask
  public faMicroscope = faMicroscope
  public faVials = faVials
  public faSearch = faSearch
  public faCaretDown = faCaretDown
  public faInfoCircle = faInfoCircle

  constructor(
    private router: Router,
    public cart: CartService,
    private api: ApiService,
    public util: UtilsService
  ) { }

  ngOnInit(): void {
    this.getCategories()
    this.cart.list()
  }

  public search(q: string) {
    if(q) {
      this.router.navigate(['/produtos'], { queryParams: { q } })
    }
  }

  private async getCategories() {
    this.isLoading = true
    await this.api.get('oapi/categories').subscribe((data: any) => {
      if(data.status) {
        this.isLoading = false
        this.categories = data.data.filter(val => val.active === true)
      }
    })
  }

}
