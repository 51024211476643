<section id="product" class="page-begin">
    <app-search-bar></app-search-bar>
    
    <div class="container content">
        <div class="row">
            <div class="col-12 col-md-3 mb-xs-15">
                <div class="loader-container" *ngIf="isLoadingCategories">
                    <mat-spinner strokeWidth="6"></mat-spinner>
                </div>
                <div class="categories" *ngIf="!isLoadingCategories">
                    <div class="categories-title">
                        <p>{{category.title}}</p>
                    </div>
                    <mat-card>
                        <mat-card-content>
                            <ul class="list-unstyled">
                                <li>
                                    <a href="" (click)="filter($event, category._id, null)">Todos</a>
                                </li>
                                <li *ngFor="let subcategory of category.subcategories; let i = index"
                                    [class.active]="product.idSubCategory === subcategory._id">
                                    <a href="" (click)="filter($event, category._id, subcategory._id)">{{subcategory.title}}</a>
                                </li>
                            </ul>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>

            <div class="col-12 col-md-9 col-lg-8 offset-lg-1">
                <div class="loader-container" *ngIf="isLoading">
                    <mat-spinner strokeWidth="6"></mat-spinner>
                </div>
                <div class="product" *ngIf="!isLoading">
                    <div class="row">
                        <div class="col-12 col-md-5 mb-xs-30">
                            <div class="box-img mb-4">
                                <img [src]="product.img">
                            </div>
                            <button type="button" class="btn secondary-btn block btn-block-xs" matRipple (click)="cart.add(product)">
                                <fa-icon [icon]="faClipboardList"></fa-icon>
                                Adicionar a cotação
                            </button>
                        </div>

                        <div class="col-12 col-md-7">
                            <h4 class="product-title">{{product.title}}</h4>
                            <hr>
                            <p [innerHTML]="product.description"></p>
                            <a [href]="product.file" target="_blank" class="btn secondary-btn btn-block-xs" matRipple *ngIf="product.file">
                                <fa-icon [icon]="faFile"></fa-icon>
                                Visualizar bula (.pdf)
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="related">
            <h2 class="title-line">
                Veja também
                <div class="line end"></div>
            </h2>
            <div class="loader-container" *ngIf="isLoading">
                <mat-spinner strokeWidth="6"></mat-spinner>
            </div>
            <owl-carousel-o [options]="customOptions" class="products-carousel" *ngIf="!isLoading">
                <ng-container *ngFor="let product of products; let i = index;">
                    <ng-template carouselSlide [id]="i">
                        <div class="box-product">
                            <a [routerLink]="['/produto', product._id]">
                                <div class="box-img d-flex align-center justify-content-center">
                                    <img [src]="product.img">
                                </div>
                                <hr>
                                <h3 class="product-title">{{product.title}}</h3>
                                <p>Ver informações</p>
                            </a>
                        </div>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
        </div>
    </div>
</section>