<section id="profile" class="page-begin">
    <app-search-bar></app-search-bar>
    
    <div class="container content">
        <div class="row">
            <div class="col-12 col-lg-10 offset-lg-1">
                <h2 class="title-line mb-5">
                    Meus dados
                    <span class="line end"></span>
                </h2>

                <div class="loader-container" *ngIf="isLoading">
                    <mat-spinner strokeWidth="6"></mat-spinner>
                </div>

                <ng-container *ngIf="!isLoading">
                    <form [formGroup]="formUser">
                        <div class="row">
                            <div class="col-12 col-lg-6 mb-3">
                                <label for="name">Nome *</label>
                                <input type="text" class="form-control" id="name" formControlName="name" required>
                            </div>
                            <div class="col-12 col-lg-6 mb-3">
                                <label for="email">E-mail *</label>
                                <input type="email" class="form-control" id="email" formControlName="email" required>
                            </div>
                            <div class="col-12 col-lg-6 mb-3">
                                <label for="phone">Telefone</label>
                                <input type="text" class="form-control" id="phone" formControlName="phone" mask="(00) 0000-0000 || (00) 0 0000-0000" [dropSpecialCharacters]="false">
                            </div>
                            <ng-container formGroupName="address">
                                <div class="col-12 col-lg-6 mb-3">
                                    <label for="cep">CEP</label>
                                    <input type="text" class="form-control" id="cep" formControlName="cep" mask="00000-000" [dropSpecialCharacters]="false" (change)="getAddressByCep(formUser.value.address.cep)">
                                </div>
                                <div class="col-12 col-lg-9 mb-3">
                                    <label for="street">Rua</label>
                                    <input type="text" class="form-control" id="street" formControlName="street">
                                </div>
                                <div class="col-12 col-lg-3 mb-3">
                                    <label for="number">Número</label>
                                    <input type="number" class="form-control" id="number" min="1" formControlName="number">
                                </div>
                                <div class="col-12 col-lg-4 mb-3">
                                    <label for="complement">Complemento</label>
                                    <input type="text" class="form-control" id="complement" formControlName="complement">
                                </div>
                                <div class="col-12 col-lg-8 mb-3">
                                    <label for="neighborhood">Bairro</label>
                                    <input type="text" class="form-control" id="neighborhood" formControlName="neighborhood">
                                </div>
                                <div class="col-12 col-lg-8 mb-3">
                                    <label for="city">Cidade</label>
                                    <input type="text" class="form-control" id="city" formControlName="city">
                                </div>
                                <div class="col-12 col-lg-4 mb-3">
                                    <label for="state">Estado</label>
                                    <select class="form-control" id="state" formControlName="state">
                                        <option value="" disabled selected>Selecione um estado</option>
                                        <option *ngFor="let state of states | orderBy:'sigla':'asc'" [value]="state.sigla">{{state.sigla}}</option>
                                    </select>
                                </div>
                            </ng-container>
                            <div class="col-12 form-confirm">
                                <button type="button" class="btn main-btn btn-block-xs btn-block-sm" matRipple (click)="changePassword()">
                                    <fa-icon [icon]="faLock"></fa-icon>
                                    Mudar senha
                                </button>
                                <button type="submit" class="btn main-btn btn-block-xs btn-block-sm" matRipple (click)="save()" [disabled]="isLoadingSave || formUser.pristine">
                                    <fa-icon [icon]="faSave"></fa-icon>
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </form>
                </ng-container>
            </div>
        </div>
    </div>
</section>