import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from 'src/app/services/utils.service';
import { PageScrollService } from 'ngx-page-scroll-core';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { DOCUMENT } from '@angular/common';
import * as $ from 'jquery';
import { SeoService } from 'src/app/services/seo.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  public faInfoCircle = faInfoCircle

  public categories = []
  public category: any = {}

  public products = []
  
  public queryParams: any = {}
  public searchMode: boolean = false
  
  public isLoading: boolean = false
  public isLoadingCategories: boolean = false

  public step: number = null

  private mobileScreen = $(window).width() < 992

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private util: UtilsService,
    private router: Router,
    private pageScrollService: PageScrollService,
    @Inject(DOCUMENT) private document: any,
    private seo: SeoService
  ) { }

  ngOnInit(): void {
    if(this.router.url === '/produtos') this.router.navigate(['/produtos'], { queryParams: { categoria: 'reagentes' } })
    this.route.queryParams.subscribe((data: any) => {
      this.queryParams = data
      this.getProducts()
      
      // Verifica se está no modo busca
      if(this.router.url.includes('/produtos?q=')) {
        this.searchMode = true
      } else {
        this.searchMode = false
        this.getCategories()
      }
    })
  }

  private getCategories() {
    this.isLoadingCategories = true
    this.api.get('oapi/categories').subscribe((data: any) => {
      if(data.status) {
        this.category = data.data.filter(val => val._id === this.queryParams.categoria)[0]
        this.getSubCategories(this.category._id)
        this.isLoadingCategories = false
        this.setTags()
      }
    })
  }

  private getSubCategories(idCategory) {
    this.api.get(`oapi/subcategories/${idCategory}`).subscribe((data: any) => {
      if(data.status) {
        this.category.subcategories = data.data.filter(val => val.active === true)
      }
    })
  }

  private async getProducts() {
    this.isLoading = true
    await this.api.get('oapi/products').subscribe((data: any) => {
      this.isLoading = false
      if(data.status) {
        if(!this.queryParams) {
          this.products = data.data.filter(val => val.active === true)
        } else if(this.queryParams.subcategoria) {
          this.products = data.data.filter(val => val.idSubCategory === this.queryParams.subcategoria && val.active === true)
        } else if(this.queryParams.q) {
          this.products = data.data.filter(val => this.util.slug(val.title).includes(this.util.slug(this.queryParams.q)) && val.active === true)
        } else {
          this.products = data.data.filter(val => val.idCategory === this.queryParams.categoria && val.active === true)
        }
      }
    })
  }

  public filter(e, categ, subcateg) {
    e.preventDefault()
    this.router.navigate(['/produtos'], { queryParams: { categoria: categ, subcategoria: subcateg }, queryParamsHandling: 'merge' })

    if(this.mobileScreen) {
      this.pageScrollService.scroll({
        document: this.document,
        scrollTarget: '.products',
        duration: 500,
        scrollOffset: 100
      })
    }
  }

  public setStep(index: number) {
    this.step = index
  }

  private setTags() {
    this.seo.setTitle(`Produtos - ${this.category.title} | Forentec`)
    this.seo.updateTag('og:title', `${this.category.title} | Forentec`)
    this.seo.updateTag('og:description', `Encontre os melhores ${this.category.title} para sua necessidade, aqui na Forentec`)
    this.seo.updateTag('og:url', window.location.href)
    this.seo.updateTag('description', `Encontre os melhores ${this.category.title} para sua necessidade, aqui na Forentec`)
    this.seo.updateTag('keywords', 'sangue, saliva, sêmen, amilase, mancha, imunocromatográfico, pesquisa, forense, fonrensics, seratec, hemoglobina, hem, menstrual, psa, pmb, fob, oculto')
  }

}
