<div matDialogTitle>
    <h2>Mudar senha</h2>
</div>
<mat-dialog-content (keydown.enter)="send()">
    <form [formGroup]="formPassword">
        <div class="row">
            <div class="col-12 col-md-4 mb-3">
                <label for="actualPassword">Senha atual</label>
                <div class="form-password">
                    <input [type]="input1" class="form-control" id="actualPassword" formControlName="actualPassword" required>
                    <button class="btn" type="button" (click)="reveal('#actualPassword')" tabindex="-1">
                        <fa-icon [icon]="icon1"></fa-icon>
                    </button>
                </div>
            </div>
            <div class="col-12 col-md-4 mb-3">
                <label for="password">Nova senha</label>
                <div class="form-password">
                    <input [type]="input2" class="form-control" id="password" formControlName="password" required>
                    <button class="btn" type="button" (click)="reveal('#password')" tabindex="-1">
                        <fa-icon [icon]="icon2"></fa-icon>
                    </button>
                </div>
            </div>
            <div class="col-12 col-md-4 mb-3">
                <label for="confirmPassword">Confirmar nova senha</label>
                <div class="form-password">
                    <input [type]="input3" class="form-control" id="confirmPassword" formControlName="confirmPassword" required>
                    <button class="btn" type="button" (click)="reveal('#confirmPassword')" tabindex="-1">
                        <fa-icon [icon]="icon3"></fa-icon>
                    </button>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions class="form-confirm">
    <button class="btn main-btn" (click)="close()" matRipple>Fechar</button>
    <button class="btn main-btn" (click)="send()" matRipple [disabled]="isLoading">Enviar</button>
</mat-dialog-actions>