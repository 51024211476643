import { Injectable, Output, EventEmitter } from '@angular/core';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public token = 'codeartdashboard'
  public user: any = {}

  constructor(
    private util: UtilsService
  ) { }

  @Output() fireIsLoggedIn: EventEmitter<any> = new EventEmitter<any>()

  setUser(data) {
    this.util.set(this.token, data)
    this.fireIsLoggedIn.emit(data)
  }

  public getLoginEvent = () => this.fireIsLoggedIn

  public userVerify = () => this.util.get(this.token)

  public logout() {
    this.util.del(this.token)
    this.fireIsLoggedIn.emit({...this.user})
  }
}
