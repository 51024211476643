import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { ProductsComponent } from './pages/products/products.component';
import { ProductComponent } from './pages/product/product.component';
import { ContactComponent } from './pages/contact/contact.component';
import { LoginComponent } from './pages/login/login.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { HistoricComponent } from './pages/historic/historic.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { LoginGuard } from './guards/login.guard';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'sobre', component: AboutComponent },
  { path: 'produtos', component: ProductsComponent },
  { path: 'produto/:id', component: ProductComponent },
  { path: 'contato', component: ContactComponent },
  { path: 'login', component: LoginComponent },
  { path: 'perfil', component: ProfileComponent, canActivate: [LoginGuard] },
  { path: 'historico', component: HistoricComponent, canActivate: [LoginGuard] },
  { path: 'carrinho', component: CheckoutComponent },
  { path: 'resetar-senha/:id', component: ResetPasswordComponent },
  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
