import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Contents } from 'src/app/models/contents.model';
import { SeoService } from 'src/app/services/seo.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  public contents: Contents[] = []
  public about: Contents = <Contents>{}

  constructor(
    private api: ApiService,
    private seo: SeoService
  ) { }

  ngOnInit(): void {
    this.getContents()
  }

  private async getContents() {
    await this.api.get('oapi/contents').subscribe((data: any) => {
      if(data.status) {
        this.contents = data.data
        this.about = this.contents.filter(val => val.section === 'Sobre' && val.active === true)[0]
        this.setTags()
      }
    })
  }

  private setTags() {
    this.seo.setTitle(`Sobre nós | Forentec`)
    this.seo.updateTag('og:title', `Sobre nós | Forentec`)
    this.seo.updateTag('og:description', this.about.content.length >= 150 ? this.about.content.slice(0,147)+'...' : this.about.content)
    this.seo.updateTag('og:url', window.location.href)
    this.seo.updateTag('description', this.about.content.length >= 150 ? this.about.content.slice(0,147)+'...' : this.about.content)
    this.seo.updateTag('keywords', 'sangue, saliva, sêmen, amilase, mancha, imunocromatográfico, pesquisa, forense, fonrensics, seratec, hemoglobina, hem, menstrual, psa, pmb, fob, oculto')
  }

}
