import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Address } from 'src/app/models/address.model';
import { Contacts } from 'src/app/models/contacts.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { faPhone, faEnvelope, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp, faInstagram, faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import * as $ from 'jquery';
import { AuthService } from 'src/app/services/auth.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  formNewsletter: FormGroup
  
  public user: any = {}

  public address: Address = <Address>{}
  public attendance: string = ''
  public contacts: Contacts[] = []

  public isLoading: boolean = false

  public faPhone = faPhone
  public faEnvelope = faEnvelope
  public faChevronUp = faChevronUp
  public faWhatsapp = faWhatsapp
  public faInstagram = faInstagram
  public faFacebook = faFacebook
  public faLinkedin = faLinkedin

  constructor(
    private api: ApiService,
    private fb: FormBuilder,
    private auth: AuthService,
    public util: UtilsService
  ) { }

  ngOnInit(): void {
    this.getAddress()
    this.getContacts()
    this.prepareForm()
    this.auth.getLoginEvent().subscribe(data => {
      this.user = data
    })
    if(this.auth.userVerify()) this.user = this.auth.userVerify()
  }

  private prepareForm() {
    this.formNewsletter = this.fb.group({
      name: this.fb.control('', [Validators.required]),
      email: this.fb.control('', [Validators.required, Validators.email])
    })
  }

  private async getAddress() {
    await this.api.get('oapi/address').subscribe((data: any) => {
      if(data.status) {
        this.address = data.data[0].address
        this.attendance = data.data[0].attendance
      }
    })
  }

  private async getContacts() {
    await this.api.get('oapi/contacts').subscribe((data: any) => {
      if(data.status) {
        this.contacts = data.data.filter(val => val.active === true)
      }
    })
  }

  public goToTop() {
    $('html, body').animate({ scrollTop: 0 }, 500)
  }

  public async send() {
    if(!this.formNewsletter.valid) {
      const arr = this.util.getFormValidationErrors(this.formNewsletter)
      const newArr = arr.map(el => this.util.translate(el.field)).join(', ')
      this.util.swal('Ops!', `Preencha todos os campos obrigatórios.<br/> <b>${newArr}</b>`, 'error')
    } else {
      this.isLoading = true
      await this.api.post('oapi/newsletter', this.formNewsletter.value).subscribe((data: any) => {
        this.isLoading = false
        this.util.toast('Inscrito com sucesso!', 'success')
        this.formNewsletter.reset()
      }, (err: any) => {
        this.isLoading = false
        this.util.swal('Ops!', err.error.errors, 'error')
      })
    }
  }

}
