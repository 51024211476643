import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-modal-historic-details',
  templateUrl: './modal-historic-details.component.html',
  styleUrls: ['./modal-historic-details.component.scss']
})
export class ModalHistoricDetailsComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<ModalHistoricDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public util: UtilsService
  ) { }

  ngOnInit(): void { }

  close(): void {
    this.dialogRef.close()
  }

}
