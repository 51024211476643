import { Injectable, Output, EventEmitter } from '@angular/core';
import { UtilsService } from './utils.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  public cart = []
  private storageToken = 'carrinho'
  @Output() cartEvent = new EventEmitter()

  constructor(
    private util: UtilsService
  ) { }

  public add(item) {
    // this.cart = this.list()
    this.cart.push(item)
    this.util.setSessionStorage(this.storageToken, this.cart)
    this.util.toast('Item adicionado!', 'success')
    this.list()
    // console.log('add', this.cart)
  }

  public list() {
    if(this.util.getSessionStorage(this.storageToken)) {
      this.cart = this.util.getSessionStorage(this.storageToken)
      return this.cart
    } else {
      return this.cart
    }
  }

  public removeItem(item) {
    _.remove(this.cart, (val) => val._id === item._id)
    this.util.setSessionStorage(this.storageToken, this.cart)
    this.util.toast('Item removido!', 'success')
    this.list()
    this.cartEvent.emit(true)
  }

  public removeAll() {
    this.cart = []
    this.util.delSessionStorage(this.storageToken)
    this.util.toast('Seu carrinho foi limpo!', 'success')
    this.list()
  }

  public aggregate(arr) {
    const newArr = []
    const uniq = _.uniqBy(arr, '_id')
    const countBy = _.countBy(arr, '_id')

    uniq.forEach(el => {
      Object.keys(countBy).forEach(val => {
        if(val === el._id) {
          newArr.push({
            ...el,
            count: countBy[val]
          })
        }
      })
    })
    return newArr
  }

  public listenCartEvents = () => this.cartEvent
}
