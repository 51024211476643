import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { SeoService } from 'src/app/services/seo.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  formContact: FormGroup

  public tabActive: number = 1
  
  public isLoading: boolean = false

  public sectors = ['Vendas', 'Assessoria', 'Licitações/cotações', 'Financeiro']
  public states = []

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private api: ApiService,
    private util: UtilsService,
    private seo: SeoService
  ) { }

  ngOnInit(): void {
    this.prepareFormContact()
    this.getStates()
    this.setTags()
  }

  private prepareFormContact() {
    this.formContact = this.fb.group({
      sector: this.fb.control('', [Validators.required]),
      subject: this.fb.control('', [Validators.required]),
      name: this.fb.control('', [Validators.required]),
      company: this.fb.control(''),
      email: this.fb.control('', [Validators.required, Validators.email]),
      phone: this.fb.control(''),
      state: this.fb.control(''),
      city: this.fb.control(''),
      message: this.fb.control('', [Validators.required])
    })
  }

  private async getStates() {
    await this.http.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados').subscribe((data: any) => {
      this.states = data
    })
  }

  public async send() {
    if(!this.formContact.valid) {
      const arr = this.util.getFormValidationErrors(this.formContact)
      const newArr = arr.map(el => this.util.translate(el.field)).join(', ')
      this.util.swal('Ops!', `Preencha todos os campos obrigatórios.<br/> <b>${newArr}</b>`, 'error')
    } else {
      this.isLoading = true
      await this.api.post('oapi/send-mail', this.formContact.value).subscribe((data: any) => {
        this.isLoading = false
        if(data.status) {
          this.util.toast(data.msg, 'success')
          this.formContact.reset()
        } else {
          this.util.toast(data.msg, 'error')
        }
      }, (err: any) => {
        this.isLoading = false
        this.util.swal('Ops!', err.error.errors, 'error')
      })
    }
  }

  private setTags() {
    this.seo.setTitle(`Fale conosco | Forentec`)
    this.seo.updateTag('og:title', `Fale conosco | Forentec`)
    this.seo.updateTag('og:description', 'Entre em contato com a nossa equipe através dos nossos setores.')
    this.seo.updateTag('og:url', window.location.href)
    this.seo.updateTag('description', 'Entre em contato com a nossa equipe através dos nossos setores.')
    this.seo.updateTag('keywords', 'sangue, saliva, sêmen, amilase, mancha, imunocromatográfico, pesquisa, forense, fonrensics, seratec, hemoglobina, hem, menstrual, psa, pmb, fob, oculto')
  }

}
