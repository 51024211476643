<section id="reset-password" class="page-begin">
    <app-search-bar></app-search-bar>
    
    <div class="container content">
        <div class="row">
            <div class="col-12 col-md-10 offset-md-1">
                <h3>Digite sua nova senha</h3>
                <form [formGroup]="form">
                    <div class="row">
                        <div class="col-12 col-md-5 mb-xs-15">
                            <label for="password">Nova senha</label>
                            <div class="form-password">
                                <input [type]="input1" class="form-control" id="password" formControlName="password" required>
                                <button class="btn" type="button" (click)="reveal('#password')" tabindex="-1">
                                    <fa-icon [icon]="icon1"></fa-icon>
                                </button>
                            </div>
                        </div>
                        <div class="col-12 col-md-5 mb-xs-15">
                            <label for="confirmPassword">Confirmar nova senha</label>
                            <div class="form-password">
                                <input [type]="input2" class="form-control" id="confirmPassword" formControlName="confirmPassword" required>
                                <button class="btn" type="button" (click)="reveal('#confirmPassword')" tabindex="-1">
                                    <fa-icon [icon]="icon2"></fa-icon>
                                </button>
                            </div>
                        </div>
                        <div class="col-12 col-md-2 d-flex align-items-end">
                            <button type="submit" class="btn main-btn btn-block" (click)="send()" matRipple>Confirmar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>        
    </div>
</section>