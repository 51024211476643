<section id="about" class="page-begin">
    <app-search-bar></app-search-bar>
    
    <div class="container content">
        <div class="d-flex justify-content-center mb-5">
            <img src="assets/img/logo.png" class="img-fluid">
        </div>
        <p [innerHTML]="about.content"></p>
        
        <div class="row mt-5">
            <div class="col-12 col-md-8 offset-md-2">
                <div class="row">
                    <div class="col-12 col-md-4 mb-xs-15">
                        <h4 class="text-center">Responsabilidade</h4>
                    </div>
                    <div class="col-12 col-md-4 mb-xs-15">
                        <h4 class="text-center">Seriedade</h4>
                    </div>
                    <div class="col-12 col-md-4">
                        <h4 class="text-center">Comprometimento</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>