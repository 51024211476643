import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidationErrors } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { NgxViacepService } from '@brunoc/ngx-viacep';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { Users } from 'src/app/models/users.model';
import { faSave, faLock } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { ModalChangePasswordComponent } from 'src/app/templates/modal-change-password/modal-change-password.component';
import { SeoService } from 'src/app/services/seo.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  formUser: FormGroup

  public faSave = faSave
  public faLock = faLock
  
  public user: any = {}

  public states = []

  public isLoading: boolean = false
  public isLoadingSave: boolean = false

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private api: ApiService,
    private util: UtilsService,
    private viacep: NgxViacepService,
    private router: Router,
    private auth: AuthService,
    private dialog: MatDialog,
    private seo: SeoService
  ) {
    if(!this.auth.userVerify()) this.router.navigate(['/'])
  }

  ngOnInit(): void {
    if(!this.auth.userVerify()) {
      this.router.navigate(['/'])
    } else {
      this.getUser()
      this.setTags()
    }
  }

  private prepareForm() {
    this.formUser = this.fb.group({
      name: this.fb.control(this.user.name, [Validators.required]),
      email: this.fb.control(this.user.email, [Validators.email, Validators.required]),
      phone: this.fb.control(this.user.phone),
      address: this.fb.group({
        cep: this.fb.control(this.user.address.cep),
        street: this.fb.control(this.user.address.street),
        number: this.fb.control(this.user.address.number),
        complement: this.fb.control(this.user.address.complement),
        neighborhood: this.fb.control(this.user.address.neighborhood),
        city: this.fb.control(this.user.address.city),
        state: this.fb.control(this.user.address.state)
      }),
      active: this.fb.control(this.user.active)
    })
    this.getStates()
  }

  private async getUser() {
    this.isLoading = true
    await this.api.getWithHeaders(`client-api/users/${this.auth.userVerify()._id}`, this.auth.userVerify().token).subscribe((data: any) => {
      this.isLoading = false
      if(data.status) {
        this.user = data.data
        this.prepareForm()
      }
    })
  }

  private async getStates() {
    await this.http.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados').subscribe((data: any) => {
      this.states = data
    })
  }

  public async getAddressByCep(cep) {
    await this.viacep.buscarPorCep(cep).then((data: any) => {
      if(data) {
        const address = {
          street: data.logradouro,
          neighborhood: data.bairro,
          city: data.localidade,
          state: data.uf
        }
        this.formUser.patchValue({ address })
      }
     }).catch((err: any) => {
      console.log(err.message)
     })
  }

  public async save() {
    if(!this.formUser.valid) {
      const arr = this.getFormValidationErrors(this.formUser)
      const newArr = arr.map(el => this.util.translate(el.field)).join(', ')
      this.util.swal('Ops!', `Preencha todos os campos obrigatórios.<br/> <b>${newArr}</b>`, 'error')
    } else {
      this.isLoadingSave = true
      await this.api.putWithHeaders(this.auth.userVerify().token, `client-api/users/${this.auth.userVerify()._id}`, this.formUser.value).subscribe((data: any) => {
        this.isLoadingSave = false
        if(data.status) {
          this.auth.setUser({
            ...this.auth.userVerify(),
            email: this.formUser.value.email,
            name: this.formUser.value.name
          })
          this.util.swal('Tudo certo!', 'Seus dados foram atualizados.', 'success')
        }
      }, (err: any) => {
        this.util.swal('Ops!', err.error.errors, 'error')
        this.isLoadingSave = false
      })
    }
  }

  private getFormValidationErrors(form) {
    const errors = []
    Object.keys(form.controls).forEach(key => {
      const controlErrors: ValidationErrors = form.get(key).errors
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          if(keyError === 'required') {
            errors.push({field: key, invalid: controlErrors[keyError]})
          }
        })
      }
    })
    return errors
  }

  public changePassword() {
    this.dialog.open(ModalChangePasswordComponent, {
      width: '900px',
      maxWidth: ''
    })
  }

  private setTags() {
    this.seo.setTitle(`Perfil | Forentec`)
    this.seo.updateTag('og:title', `Perfil | Forentec`)
    this.seo.updateTag('og:description', 'Este é o seu perfil da Forentec')
    this.seo.updateTag('og:url', window.location.href)
    this.seo.updateTag('description', 'Este é o seu perfil da Forentec')
    this.seo.removeTag('keywords')
  }

}
