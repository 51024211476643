import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { MaterialModule } from './material.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgxViacepModule } from '@brunoc/ngx-viacep';

import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';
registerLocaleData(ptBr)

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './pages/home/home.component';
import { FilterPipe } from './pipes/filter.pipe';
import { HeaderComponent } from './templates/header/header.component';
import { FooterComponent } from './templates/footer/footer.component';
import { BannerComponent } from './templates/banner/banner.component';
import { SearchBarComponent } from './templates/search-bar/search-bar.component';
import { OrderByPipe } from './pipes/order-by.pipe';
import { AboutComponent } from './pages/about/about.component';
import { ProductsComponent } from './pages/products/products.component';
import { ContactComponent } from './pages/contact/contact.component';
import { LoginComponent } from './pages/login/login.component';
import { ProductComponent } from './pages/product/product.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { HistoricComponent } from './pages/historic/historic.component';
import { UniqPipe } from './pipes/uniq.pipe';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { ModalHistoricDetailsComponent } from './templates/modal-historic-details/modal-historic-details.component';
import { ModalChangePasswordComponent } from './templates/modal-change-password/modal-change-password.component';
import { ModalResetPasswordComponent } from './templates/modal-reset-password/modal-reset-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FilterPipe,
    HeaderComponent,
    FooterComponent,
    BannerComponent,
    SearchBarComponent,
    OrderByPipe,
    AboutComponent,
    ProductsComponent,
    ContactComponent,
    LoginComponent,
    ProductComponent,
    ProfileComponent,
    HistoricComponent,
    UniqPipe,
    CheckoutComponent,
    ModalHistoricDetailsComponent,
    ModalChangePasswordComponent,
    ModalResetPasswordComponent,
    ResetPasswordComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FontAwesomeModule,
    CarouselModule,
    NgxMaskModule.forRoot(),
    ToastrModule.forRoot(),
    NgxPageScrollCoreModule,
    SweetAlert2Module.forRoot(),
    NgxViacepModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ModalHistoricDetailsComponent,
    ModalChangePasswordComponent,
    ModalResetPasswordComponent
  ]
})
export class AppModule { }
