<owl-carousel-o [options]="customOptions" id="carouselBanner">
    <ng-container *ngFor="let banner of banners; let i = index;">
        <ng-template carouselSlide [id]="i">
            <div class="box-img">
                <a [href]="banner.url">
                    <img [src]="banner.img">
                </a>
            </div>
        </ng-template>
    </ng-container>
</owl-carousel-o>