import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { ValidationErrors } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  public set = (key: string, obj) => window.localStorage.setItem(key, JSON.stringify(obj));
  public get = (key: string) => JSON.parse(window.localStorage.getItem(key));
  public del = (key: string) => window.localStorage.removeItem(key);

  public setSessionStorage = (key: string, obj) => window.sessionStorage.setItem(key, JSON.stringify(obj));
  public getSessionStorage = (key: string) => JSON.parse(window.sessionStorage.getItem(key));
  public delSessionStorage = (key: string) => window.sessionStorage.removeItem(key);

  public getImgUrl(img) {
    const newPath = img.split('dist/')[1]
    const finalString = `${environment.baseUrl}/${newPath}`
    return finalString
  }

  public slug(val: string) {
    val = val.replace(/[áàãâä]/g, 'a')
    val = val.replace(/[ÁÀÃÂÄ]/g, 'a')
    val = val.replace(/[éèêë]/g, 'e')
    val = val.replace(/[ÉÈÊË]/g, 'e')
    val = val.replace(/[íìîï]/g, 'i')
    val = val.replace(/[ÍÌÎÏ]/g, 'i')
    val = val.replace(/[óòõôö]/g, 'o')
    val = val.replace(/[ÓÒÕÔÖ]/g, 'o')
    val = val.replace(/[úùûü]/g, 'u')
    val = val.replace(/[ÚÙÛÜ]/g, 'u')
    val = val.replace(/[ç]/g, 'c')
    val = val.replace(/[Ç]/g, 'c')
    return val.toLowerCase()
  }

  public swal(title, html, icon, confirmButtonText = 'OK') {
    const swal = Swal.mixin({
      customClass: {
        confirmButton: 'btn main-btn'
      },
      buttonsStyling: false
    })
    return swal.fire({ title, html, icon, confirmButtonText })
  }

  public toast(title, icon) {
    const toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
    return toast.fire({title, icon})
  }

  public capitalize(s) {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  public translate(val: string) {
    switch(val) {
      case 'name':
        return 'Nome';
      case 'email':
        return 'E-mail';
      case 'phone':
        return 'Telefone';
      case 'cep':
        return 'CEP';
      case 'street':
        return 'Rua';
      case 'neighborhood':
        return 'Bairro';
      case 'city':
        return 'Cidade';
      case 'state':
        return 'Estado';
      case 'number':
        return 'Número';
      case 'complement':
        return 'Complemento';
      case 'password':
        return 'Senha';
      case 'confirmPassword':
        return 'Confirmar senha';
      case 'actualPassword':
        return 'Senha atual';
      case 'sector':
      return 'Setor';
      case 'subject':
        return 'Assunto';
      case 'company':
        return 'Empresa';
      case 'message':
        return 'Mensagem';
    }
  }

  public getFormValidationErrors(form) {
    const errors = []
    Object.keys(form.controls).forEach(key => {
      const controlErrors: ValidationErrors = form.get(key).errors
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          if(keyError === 'required') {
            errors.push({field: key, invalid: controlErrors[keyError]})
          }
        })
      }
    })
    return errors
  }

  public formatNumber(number: string): string {
    return number.replace('(', '').replace(')', '').replace('.', '').replace(/\s+/g, '');
  }
}