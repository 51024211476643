import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { UtilsService } from 'src/app/services/utils.service';
import { SeoService } from 'src/app/services/seo.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  form: FormGroup

  public data: any = {}
  public isLoading: boolean = false
  public redirect: boolean = false
  public countdown = 5

  public icon1 = faEye
  public icon2 = faEye

  public input1 = 'password'
  public input2 = 'password'

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private fb: FormBuilder,
    private router: Router,
    private util: UtilsService,
    private seo: SeoService
  ) {
    this.route.params.subscribe(data => {
      this.data = data
    })
  }

  ngOnInit(): void {
    this.prepareForm()
    this.setTags()
  }

  private prepareForm() {
    this.form = this.fb.group({
      password: this.fb.control('', [Validators.required]),
      confirmPassword: this.fb.control('', [Validators.required])
    })
  }

  public async send() {
    if(!this.form.valid) {
      const arr = this.getFormValidationErrors(this.form)
      const newArr = arr.map(el => this.util.translate(el.field)).join(', ')
      this.util.swal('Ops!', `Preencha todos os campos obrigatórios.<br/> <b>${newArr}</b>`, 'error')
    } else {
      this.isLoading = true
      await this.api.put(`oapi/reset-user-password/${this.data.id}`, this.form.value).subscribe((data: any) => {
        this.isLoading = false
        if(data.status) {
          this.form.reset()
          this.util.swal('Tudo certo!', data.msg, 'success').then(res => {
            if(res) {
              this.router.navigate(['/login'])
            }
          })
        }
      }, (err: any) => {
        this.isLoading = false
        this.util.swal('Ops!', err.error.errors, 'error')
      })
    }
  }

  public reveal(id) {
    switch(id) {
      case '#password':
        this.icon1 === faEye ? this.icon1 = faEyeSlash : this.icon1 = faEye
        this.input1 === 'password' ? this.input1 = 'text' : this.input1 = 'password'
        break;
      case '#confirmPassword':
        this.icon2 === faEye ? this.icon2 = faEyeSlash : this.icon2 = faEye
        this.input2 === 'password' ? this.input2 = 'text' : this.input2 = 'password'
        break;
    }
  }

  private getFormValidationErrors(form) {
    const errors = []
    Object.keys(form.controls).forEach(key => {
      const controlErrors: ValidationErrors = form.get(key).errors
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          if(keyError === 'required') {
            errors.push({field: key, invalid: controlErrors[keyError]})
          }
        })
      }
    })
    return errors
  }

  private setTags() {
    this.seo.setTitle(`Resetar senha | Forentec`)
    this.seo.updateTag('og:title', `Resetar senha | Forentec`)
    this.seo.updateTag('og:description', 'Resete sua senha de login')
    this.seo.updateTag('og:url', window.location.href)
    this.seo.updateTag('description', 'Resete sua senha de login')
    this.seo.updateTag('keywords', 'sangue, saliva, sêmen, amilase, mancha, imunocromatográfico, pesquisa, forense, fonrensics, seratec, hemoglobina, hem, menstrual, psa, pmb, fob, oculto')
  }

}
