<footer class="content">
    <div class="container">
        <div class="row mb-5">
            <div class="col-12">
                <h2 class="text-center mb-4">Cadastre-se e receba nossas novidades</h2>
                <form [formGroup]="formNewsletter">
                    <input type="text" class="form-control" placeholder="Seu nome" formControlName="name">
                    <input type="email" class="form-control" placeholder="Seu endereço de e-mail" formControlName="email">
                    <button type="submit" class="btn main-btn btn-block-xs" matRipple (click)="send()" [disabled]="isLoading">Enviar</button>
                </form>
            </div>
        </div>
        <div class="row mb-5">
            <div class="col-12 col-md-4 mb-xs-15">
                <img src="assets/img/logo-mono.png" class="img-fluid mb-4">
                <ul class="list-unstyled multi-column">
                    <li><a routerLink="/sobre">A Forentec</a></li>
                    <li><a [routerLink]="['/produtos']" [queryParams]="{categoria:'reagentes'}">Reagentes</a></li>
                    <li><a [routerLink]="['/produtos']" [queryParams]="{categoria:'equipamentos'}">Equipamentos</a></li>
                    <li><a [routerLink]="['/produtos']" [queryParams]="{categoria:'acessorios'}">Acessórios</a></li>
                    <li><a routerLink="/contato">Contato</a></li>
                    <li><a routerLink="/login" *ngIf="!user.token">Cadastre-se | Login</a></li>
                    <li><a routerLink="/perfil" *ngIf="user.token">Meu perfil</a></li>
                </ul>
            </div>
            <div class="col-12 col-md-4 mb-xs-15">
                <h3 class="text-uppercase mb-4">Onde estamos</h3>
                <ul class="list-unstyled">
                    <li>
                        <p>{{address.street}}, {{address.number}} | {{address.neighborhood}} | {{address.city}} - {{address.state}}</p>
                        <p>{{attendance}}</p>
                    </li>
                </ul>
            </div>
            <div class="col-12 offset-md-1 col-md-3 mb-xs-15">
                <h3 class="text-uppercase nowrap mb-4">Fale com a nossa equipe</h3>
                <ul class="list-unstyled">
                    <li *ngFor="let contact of contacts | orderBy:{'service': 'WhatsApp'}:'desc'">
                        <a href="{{contact.service !== 'E-mail' ? (contact.service === 'WhatsApp' ? 'https://api.whatsapp.com/send?phone=55'+util.formatNumber(contact.contact) : 'tel:+55'+contact.contact) : 'mailto:'+contact.contact}}" target="_blank">
                            <fa-icon [class.whatsapp-icon]="contact.service === 'WhatsApp'" [icon]="contact.service !== 'Telefone' ? (contact.service === 'WhatsApp' ? faWhatsapp : faEnvelope) : faPhone"></fa-icon>
                            {{contact.service !== 'WhatsApp' ? contact.contact : 'Atendimento pelo WhatsApp'}}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-4 col-sm-4">
                <button class="btn main-btn btn-top" (click)="goToTop()" matRipple><fa-icon [icon]="faChevronUp"></fa-icon></button>
            </div>
            <div class="col-8 col-sm-4 social">
                <a href="" target="_blank"><fa-icon [icon]="faInstagram"></fa-icon></a>
                <a href="" target="_blank"><fa-icon [icon]="faFacebook"></fa-icon></a>
                <a href="" target="_blank"><fa-icon [icon]="faLinkedin"></fa-icon></a>
            </div>
            <div class="col-12 col-sm-4 copyright">
                <a href="https://codeart.com.br/" target="_blank"><img src="assets/img/codeart.png"></a>
            </div>
        </div>
    </div>
</footer>