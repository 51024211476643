<section id="home" class="page-begin">
    <app-banner></app-banner>
    <app-search-bar></app-search-bar>
    
    <div class="container content">
        <div class="products">
            <h2>Produtos em destaque</h2>
            <hr>

            <div class="mt-5">
                <owl-carousel-o [options]="carouselProductsOptions" class="products-carousel">
                    <ng-container *ngFor="let product of products; let i = index;">
                        <ng-template carouselSlide [id]="i">
                            <div class="box-product">
                                <a [routerLink]="['/produto', product._id]">
                                    <div class="box-img d-flex align-center justify-content-center">
                                        <img [src]="product.img">
                                    </div>
                                    <hr>
                                    <h3 class="product-title">{{product.title}}</h3>
                                    <p>Ver informações</p>
                                </a>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>

    <div class="content">
        <owl-carousel-o [options]="carouselBannerFooterOptions" class="banner-footer">
            <ng-container *ngFor="let slide of bannerFooter; let i = index;">
                <ng-template carouselSlide [id]="i">
                    <div class="box-img">
                        <a [href]="slide.url" target="_blank">
                            <img [src]="slide.img">
                        </a>
                    </div>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </div>
</section>