<section id="historic" class="page-begin">
    <app-search-bar></app-search-bar>
    
    <div class="container content">
        <div class="row">
            <div class="col-12 col-lg-10 offset-lg-1">
                <h2 class="title-line mb-5">
                    Meu histórico
                    <span class="line end"></span>
                </h2>

                <div class="loader-container" *ngIf="isLoading">
                    <mat-spinner strokeWidth="6"></mat-spinner>
                </div>

                <div class="alert alert-info" *ngIf="!isLoading && !requests.length">
                    <fa-icon [icon]="faInfoCircle"></fa-icon>
                    Você ainda não realizou nenhum pedido
                </div>

                <div class="table-responsive" *ngIf="!isLoading && requests.length">
                    <table class="table table-hovered table-striped table-bordered">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Produtos</th>
                                <th>Data da compra</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of requests; let i = index">
                                <td>{{i+1}}</td>
                                <td>
                                    <button type="button" class="btn btn-sm btn-default" (click)="openDetails(item.items)" matRipple>Ver detalhes</button>
                                </td>
                                <td>{{item.createdAt | date:'dd/MM/yyyy'}} às {{item.createdAt | date:'HH:mm:ss'}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
</section>